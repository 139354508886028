<template>
    <Html :lang="htmlAttrs.lang" :dir="htmlAttrs.dir">
      <div class="q-layout q-layout--standard">
        <PageHeader :showMenu="false" :campaignLogo="'backseatgeeks'" />

        <slot />

        <PageFooter :campaignLogo="'backseatgeeks'" :templateType="'campaign'" />

        <Transition name="slide-fade-down">
            <ModalContainer v-if="openModal" :iframeLink="'https://taccd1583.emailsys1a.net/215/2942/626db2117c/subscribe/form.html?_g=1738753069%22'"></ModalContainer>
        </Transition>
      </div>
    </Html>
  </template>
  
  <script setup>
  import AOS from "aos";
  import { useGeneralStore } from "@/stores/general";
    
  const head = useLocaleHead({
    addDirAttribute: true,
    addSeoAttributes: true,
  });

  const { $bus } = useNuxtApp();
  
  const htmlAttrs = computed(() => head.value.htmlAttrs);
  
  const route = useRoute();
  
  const { locale } = useI18n();
  const store = useGeneralStore();
  const { darkMode, configEnded } = storeToRefs(store);
  const openModal = ref(false);
  
  AOS.init();
  
  // fill articleList
  store.setArticleList(locale.value);
  
  watch(locale, () => {
    store.setArticleList(locale.value);
  });
  
  watch(
    () => route.path,
    () => {
      console.log("Changed route");
    },
    { immediate: true, deep: true }
  );
  
  // check and set of darkmode
  if (localStorage.getItem("wsDarkMode") === "true") {
    store.saveDarkMode(true);
  } else if (localStorage.getItem("wsDarkMode") === "false") {
    store.saveDarkMode(false);
  }
  
  $bus.$on("closeMarketingModal", (_state) => {
    openModal.value = _state.state
  })

  const setDarkMode = () => {
    if (darkMode.value) {
      document.body.classList.add("body--dark");
    } else {
      document.body.classList.remove("body--dark");
    }
  };
  
  setDarkMode();
  
  watch(darkMode, () => {
    setDarkMode();
  });
  
  store.saveLanguage(locale.value);
  </script>
  